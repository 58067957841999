<template>
  <div>
    <b-button
      variant="primary"
      @click="addCampaign"
      class="float-right mb-2"
      size="sm"
    >
      <i class="ri-add-line"></i> Bonus
    </b-button>

    <b-modal id="bonus-modal" title="Bonus" hide-footer>
      <FormulateForm
        class="bonus-form"
        v-model="formValues"
        @submit="submitForm"
      >
        <FormulateInput
          name="bonus_amount"
          type="text"
          label="Bonus amount"
          placeholder="Bonus amount"
          validation="required"
        />

        <div class="float-right">
          <FormulateInput
            type="submit"
            :label="creating ? 'Adding...' : 'Add Bonus'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { driver } from "@/config/api/drivers";

export default {
  props: ["id"],
  data() {
    return {
      formValues: {},

      creating: false,
    };
  },

  methods: {
    addCampaign() {
      this.$bvModal.show("bonus-modal");
    },
    submitForm(value) {

      const data = { ...value, userId: this.id };

      const api = driver.addBonusToDriver;
      api.data = data;
      this.creating = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Bonus is added!", {
            title: "Bonus",
            variant: "success",
            solid: true,
          });

          this.$bvModal.hide("bonus-modal");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Bonus is not added!,${err.response.data.error}`,
            {
              title: "Bonus",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>

<style>
</style>