<template>
  <Layout>
    <div class="d-flex justify-content-end">
      <AddBonusModal :id="driver && driver._id" />
    </div>
    <div class="row" v-if="driver">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">Driver Info</div>
            </div>
            <div class="row">
              <div class="col-4">Driver ID</div>
              <div class="col-8" v-if="driver">
                {{ driver.driver_id }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Mobile</div>
              <div class="col-8" v-if="driver">
                {{ driver.country_code }}- {{ driver.phone_number }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Email</div>
              <div class="col-8" v-if="driver">{{ driver.email }}</div>
            </div>
            <div class="row" v-if="!driver.email_verfied_at">
              <div class="col-4">Email Verified</div>
              <div class="col-8" v-if="driver">
                {{ driver.email_verfied_at ? "Yes" : "No" }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Amount to be Paid</div>
              <div class="col-8" v-if="driver">
                {{ driver.amount_to_be_paid }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">No. of Vehicles</div>
              <div class="col-8" v-if="driver">
                {{ driver.vehicles.length }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Max. Allowed Vehicles</div>
              <div class="col-8" v-if="driver">
                {{ driver.usage_limit }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Account Created on</div>
              <div class="col-8" v-if="driver">
                {{ moment(driver.createdAt).format("LLL") }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Device Info</div>
              <div class="col-8" v-if="driver">
                OS: {{ driver.os }}
                <br>
                OS Version: {{ driver.os_version }}
                <br>
                App Version: {{ driver.app_version }}
                <br>
                Background Location: {{ driver.bg_status || "Not Available" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="driver">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <h5>License Info</h5>

            <div v-if="driver.license">
              <div class="row">
                <div class="col-4">Full Name</div>
                <div class="col-8" v-if="driver">
                  {{ driver.license.full_name }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">License Number</div>
                <div class="col-8" key="">
                  {{ driver.license.license_number }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">License Issue Date</div>
                <div class="col-8" key="">
                  {{ moment(driver.license.issue_date).format("l") }}
                </div>
              </div>

              <div class="row">
                <div class="col-4">License Expiry Date</div>
                <div class="col-8" key="">
                  {{ moment(driver.license.expiry_date).format("l") }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">Nationality</div>
                <div class="col-8" key="">
                  {{ driver.license.nationality }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">License Files</div>
                <div class="col-4">
                  <button type="button" @click="showLicenseFiles = !showLicenseFiles">
                    {{ showLicenseFiles ? "Hide Files" : "Show Files" }}
                  </button>
                </div>
              </div>
              <div class="row" style="margin-top: 5px">
                <div class="col-6">
                  <img v-if="showLicenseFiles" :src="driver.license.license_front" height="200px" alt="" />
                </div>
                <div class="col-6">
                  <img v-if="showLicenseFiles" :src="driver.license.license_back" height="200px" alt="" />
                </div>
              </div>
            </div>
            <div v-else>No License Info Found</div>
            <div>
              <AddLicence v-on:change="licenceUpdated" :driver="driver.license" :id="driver._id" />
            </div>
          </div>

          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <h5>Bank Info</h5>
              </div>
            </div>
            <div v-if="driver.bank_account">
              <div class="row">
                <div class="col-6">Full Name</div>
                <div class="col-6" v-if="driver">
                  {{ driver.bank_account.name }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">IBAN Number</div>
                <div class="col-6" key="">
                  {{ driver.bank_account.iban }}
                </div>
              </div>

              <div class="row">
                <div class="col-6">BANK name</div>
                <div class="col-6" key="">
                  {{ driver.bank_account.bank }}
                </div>
              </div>
            </div>
            <div v-else>No Bank Info Found</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="driver">
      <div class="vehicles-of-driver" style="margin-left: 0" v-for="(item, index) in driver.vehicles" :key="index">
        <div>
          <add-partial-ad @reload="loadDriverProfile" :vehicleId="item._id" :id="id" v-if="!item.upcoming_ad"
            :availableCampaigns="availableCampaigns" />
        </div>
        <div class="row p-4">
          <div class="card vehicleinfo" style="min-width: 225px" v-on:click="driverSelected(item)">
            <div class="card-body">
              <div class="row spaceDetails">
                <p>Plate Category</p>
                <p>
                  {{ item.plate_category }}
                </p>
              </div>
              <div class="row spaceDetails">
                <p>Plate Number</p>
                <p>
                  {{ item.plate_number }}
                </p>
              </div>
              <div class="row spaceDetails">
                <p>Manufacturing Year</p>
                <p>
                  {{ item.manufacturing_year }}
                </p>
              </div>
              <div class="row spaceDetails">
                <p>Sticker Type</p>
                <p>
                  {{ item.sticker_type }}
                </p>
              </div>
              <div class="row spaceDetails">
                <p>Rating</p>
                <p>
                  {{ item.rating_count }}
                </p>
              </div>
              <div class="row spaceDetails">
                <p>Location</p>
                <p>
                  {{ item.city || 'Riyadh' }}
                </p>
              </div>
            </div>
          </div>
          <div class="card" style="min-width: 225px; margin-left: 30px" v-if="item.upcoming_ad">
            <div class="d-flex justify-content-end">
              <b-dropdown right variant="primary" class="m-2">
                <template #button-content>
                  <i class="ri-settings-2-line"></i>
                </template>
                <b-dropdown-item @click="deleteUpcommingAd(item.upcoming_ad._id)" variant="danger">
                  <span v-if="deleting">Deleting...</span>
                  <span v-else>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="card-body">
              <router-link :to="{
                name: 'CampaignsDetails',
                params: { id: item.upcoming_ad.campaign._id },
              }">
                <div class="row">
                  <p>Upcoming Ad</p>
                </div>
                <div class="row spaceDetails">
                  <p>Company</p>

                  <p>
                    {{ item.upcoming_ad.campaign.company.name }}
                  </p>
                </div>
                <div class="row spaceDetails">
                  <p>Plan</p>
                  <p>
                    {{ item.upcoming_ad.campaign.plan.name }}
                  </p>
                </div>
                <div class="row spaceDetails">
                  <p>No of days</p>
                  <p>
                    {{ item.upcoming_ad.campaign.no_of_days }}
                  </p>
                </div>
              </router-link>

              <assign-workstation @reload="loadDriverProfile" :campaignId="item.upcoming_ad._id" :data="{
                wrapping_station:
                  item.upcoming_ad && item.upcoming_ad.wrapping_station,
                schedule_date:
                  item.upcoming_ad && item.upcoming_ad.wrapping_schedule_date,
              }" />
            </div>
          </div>
        </div>

        <div class="card" style="min-width: 225px; margin-left: 30px" v-if="item.current_ad">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <p>Current Ad</p>
              <change-current-ad :current_ad="item.current_ad._id" :availableCampaigns="availableCampaigns"
                @reload="loadDriverProfile" />
            </div>

            <router-link :to="{
              name: 'CampaignsDetails',
              params: { id: item.current_ad.campaign._id },
            }">
              <div class="row px-2 mt-3 spaceDetails">
                <p>Company</p>
                <p>
                  {{ item.current_ad.campaign.company.name }}
                </p>
              </div>
              <div class="row px-2 spaceDetails">
                <p>Plan</p>
                <p>
                  {{ item.current_ad.campaign.plan.name }}
                </p>
              </div>
              <div class="row px-2 spaceDetails">
                <p>No of days</p>
                <p>
                  {{ item.current_ad.campaign.no_of_days }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="map-filters">
          <div class="row  p-2 text-center">
            <div class="col-4" v-for="sourceKey in sourceOptions" :key="sourceKey">
              <div class=" card-body vehicleinfo align-center" @click="selectNewResource(sourceKey)">
                <div :class="mobileSource == sourceKey ? `active` : ''">
                  <p>{{ sourceKey }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row  p-2">
                <div class="col-6">
                  <div class="row">

                    <div class="col-2">
                      <switches v-model="liveMode" type-bold="true" class="mt-4" label="Live Mode"></switches>
                    </div>
                    <div class="col-2">
                      <switches v-model="enablePoints" type-bold="true" class="mt-4" label="Marker Points"></switches>
                    </div>
                    <div class="col-2">
                      <switches v-model="enablePoly" type-bold="true" class="mt-4" label="Poly Lines"></switches>
                    </div>
                    <div class="col-2">
                      <switches v-model="playBackMode" type-bold="true" class="mt-4" label="Playback"></switches>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <FormulateInput type="date" name="startDate" v-model="startDate" label="Start date"
                    validation="optional" @change="loadHeatMapLocations" />

                </div>
                <div class="col-3">
                  <FormulateInput type="date" name="endDate" v-model="endDate" label="End date" validation="optional"
                    @change="loadHeatMapLocations" />

                </div>
                <!-- <b-badge v-if= "liveMode" >{{ moment(item.createdAt).format('lll') }}</b-badge> -->
                <b-badge v-if="liveMode && createdAt">{{ moment(createdAt).format("lll") }}</b-badge>

              </div>
            </div>
          </div>
        </div>


        <map-view v-if="!loadingMapData" :markerLocations="heatMapPoints" :lat="defaultLocation.lat" :customHeight="500"
          :lng="defaultLocation.lng" @onMapClick="onMapClick" :live="playbackLocationCords || liveLocationCords"
          :showMarkerPoints="enablePoints" :showPolyLine="enablePoly" @reload="loadDriverProfile" />

        <div v-if="loadingMapData" style="
                          width: 100%;
                          height: 500px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          margin-top: 50px;
                        ">
          <img src="@/assets/images/preloader.gif" style="height:100px" alt="" />
        </div>

        <!-- <vue-google-heatmap :points="heatMapPoints" :lat="defaultLocation.lat" :lng="defaultLocation.lng"
          :options="mapOptions" ref="gmap" :initial-zoom="defaultLocation.zoom" :height="500" /> -->
      </div>
    </div>
  </Layout>
</template>
<style>
.vehicleinfo {
  cursor: pointer;
}

.vehicleinfo .active {
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  color: white;

}
</style>
<script>
import Swal from "sweetalert2";
import AssignWorkstation from "@/components/driver/AssignWorkstation";
import AddPartialAd from "../../../components/driver/AddPartialAd.vue";
import Layout from "../../layouts/main";
import { analytics } from "../../../config/api/analytics";
import { driver } from "@/config/api/drivers";
import { campaigns } from "@/config/api/campaigns";
import AddLicence from "@/components/driver/AddLicence";
import AddBonusModal from "@/components/driver/AddBonusModal";
import ChangeCurrentAd from "@/components/driver/ChangeCurrentAd";
import mapView from "@/components/maps/mapPathView";
import moment from "moment";
import Switches from "vue-switches";
import { terminals } from "@/config/api/terminals";

export default {
  props: ["id"],
  components: {
    Layout,
    AssignWorkstation,
    AddPartialAd,
    AddLicence,
    AddBonusModal,
    ChangeCurrentAd,
    mapView,
    Switches
  },
  data() {
    return {
      liveMode: false,
      playBackMode: false,
      playBackIndex: 0,
      enablePoints: false,
      enablePoly: true,
      mobileSource: 'Mobile',
      sourceOptions: ['Mobile', "Tms", "manipulated_random"],
      loadingMapData: true,
      showLicenseFiles: false,
      isBusy: false,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: null,
      heatLocations: [],
      heatMapPoints: [],
      mapOptions: {
        radius: 75,
      },
      defaultLocation: {
        lat: 24.7136,
        lng: 46.6753,
        zoom: 10,
      },
      driver: undefined,
      deleting: false,
      availableCampaigns: [],
      timeoutID: undefined,//Used to track live mode callback
      liveLocationCords: undefined,//Used to track live mode driver positions
      playbackLocationCords: undefined,//Used to track playback mode driver positions
      createdAt: undefined

    };
  },
  watch: {

    liveMode(val) {
      if (val) {

        this.enableLiveMode();
      } else {
        this.liveLocationCords = undefined;
        if (typeof this.timeoutID === 'number') {

          clearTimeout(this.timeoutID);
        }
        this.timeoutID = undefined;
      }
    },
    playBackMode(val) {
      if (val) {
        this.playBackIndex = 0;
        this.enablePlayBackMode();
      } else {
        this.playbackLocationCords = undefined;
        if (typeof this.timeoutID === 'number') {

          clearTimeout(this.timeoutID);
        }
        this.timeoutID = undefined;
      }
    }
  },
  mounted() {
    this.loadDriverProfile();
  },
  beforeDestroy() {
    if (typeof this.timeoutID === 'number') {

      clearTimeout(this.timeoutID);
    }
    this.timeoutID = undefined;
  },
  methods: {

    loadLastLocation() {
      if (!this.driver) {
        return;
      }
      let api = terminals.lastLocation;
      api.params = {
        user: this.driver._id,
        source: this.mobileSource
      }
      this.generateAPI(api)
        .then((res) => {

          const locationData = res.data.last_location;

          if (locationData && locationData.length) {
            this.createdAt = locationData[0].createdAt
            this.liveLocationCords = {
              lat: locationData[0].location_LngLat.coordinates[1], lng: locationData[0].location_LngLat.coordinates[0], ...locationData[0]
            }

            this.heatLocations = [...this.heatLocations, this.liveLocationCords];
          }
          if (this.liveMode) { this.timeoutID = setTimeout(this.loadLastLocation, 5000); }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    enableLiveMode() {
      if (typeof this.timeoutID === 'number') {
        clearTimeout(this.timeoutID);
      }
      this.timeoutID = undefined;
      this.loadLastLocation()
    },
    enablePlayBackMode() {
      if (typeof this.timeoutID === 'number') {
        clearTimeout(this.timeoutID);
      }
      this.timeoutID = undefined;
      this.moveToNextPosition()
    },
    moveToNextPosition() {
      this.playBackIndex = this.playBackIndex + 1;
      if (this.heatLocations.length === 0) {
        return;
      }
      if (this.playBackIndex >= this.heatLocations.length) {
        this.playBackIndex = 0;
      }
      this.playbackLocationCords = this.heatLocations[this.playBackIndex];
      if (this.enablePlayBackMode) { this.timeoutID = setTimeout(this.moveToNextPosition, 1000); }

    },
    selectNewResource(resource) {
      this.mobileSource = resource;
      this.heatMapPoints = [];
      this.heatLocations = [];
      this.loadHeatMapLocations()
    },
    onMapClick(e) {
      const api = analytics.addLocations;
      api.data = {
        "driver": this.driver._id,
        "location": {
          "type": "Point",
          "coordinates": [e.latLng.lng(), e.latLng.lat(),]
        },
        date: moment().subtract(1, 'days').format("YYYY-MM-DD")
      }
      console.log(api)
      this.generateAPI(api)
    },
    loadHeatMapLocations() {
      this.loadingMapData = true;
      const api = analytics.admin_heatMapLocations;
      api.params = {
        vehicleId: this.driver && this.driver.vehicles[0]._id,
        source: this.mobileSource,
        start_date: this.startDate,
        end_date: this.endDate
      };
      this.generateAPI(api)
        .then((res) => {
          if (res.data.dashboardHeatLocations) {
            this.heatMapPoints = [];
            for (const key in res.data.dashboardHeatLocations) {

              if (
                Object.hasOwnProperty.call(res.data.dashboardHeatLocations, key)
              ) {
                const element = res.data.dashboardHeatLocations[key];
                let coordinates = element.location_LngLat.coordinates;
                let lat = coordinates[1];
                let lng = coordinates[0];
                this.heatMapPoints.push({ lat: lat, lng: lng, element });

                this.heatLocations = [...this.heatLocations, { lat, lng }];
              }
            }
          }
          // this.defaultLocation.lat = this.heatMapPoints[0].lat;
          // this.defaultLocation.lng = this.heatMapPoints[0].lng;
          // this.heatLocations = res.data;
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.loadingMapData = false;
        });
    },
    changeDate() {
      console.log("date changed");
      console.log("startDate", this.startDate);
      console.log("endDate", this.endDate);
      console.log("date changed");
    },
    driverSelected(data) {
      this.$router.push({ name: "VehicleInfo", params: { id: data._id } });
    },
    loadDriverProfile() {
      this.isBusy = true;
      const api = { ...driver.getById };
      api.id = this.id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          this.driver = res.data.driver;
          this.getAvaialbleCampaign();
          this.loadHeatMapLocations();
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err);
        });
      console.log("Load driver profile!");
    },
    deleteUpcommingAd(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete the upcoming Ad!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.initiateAPI(id);
        }
      });
    },
    initiateAPI(id) {
      const api = campaigns.removeAssignedDriver;
      api.data = { campaignAdId: id };
      this.deleting = true;
      this.generateAPI(api)
        .then(() => {
          Swal.fire("Deleted!", "Upcoming Ad has been deleted.", "success");
          this.loadDriverProfile();
        })
        .catch((err) => {
          console.log(err.response.data.error);
          Swal.fire("Deletion!", "Ad is not deleted.", "error");
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    licenceUpdated(data) {
      this.driver.license = data;
    },
    getAvaialbleCampaign() {
      const api = campaigns.getCampaignForDriver;
      api.id = this.id;
      this.generateAPI(api).then((res) => {
        this.availableCampaigns = res.data.campaigns;
      });
    },
  },
};
</script>

<style>
.spaceDetails {
  justify-content: space-between;
}

.vehicles-of-driver {
  background-color: #fafafa;
  padding: 12px;
  margin-bottom: 12px;
}
</style>
