<template>
  <div class="my-2">
    <b-button v-b-modal.change-ad-modal>Change Ad</b-button>

    <b-modal id="change-ad-modal" title="Change Ad" hide-footer>
      <label class="typo__label">Please choose an ad</label>
      <multiselect
        v-model="value"
        :options="availableCampaigns"
        :custom-label="nameWithLang"
        label="name"
        track-by="_id"
      ></multiselect>
      <b-button class="my-5 float-right" @click="changeAd">
        <span v-if="assigning"> Changing Ad...</span>
        <span v-else> Change Ad </span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { driver } from "@/config/api/drivers";

export default {
  props: ["availableCampaigns", "current_ad"],
  components: {
    Multiselect,
  },

  data() {
    return {
      value: null,
      assigning: false,
    };
  },
  created() {},

  methods: {
    changeAd() {
      if (!this.value) {
        this.$bvToast.toast("Please choose atleast one ad!", {
          title: "Change Ad",
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.assigning = true;
      const api = driver.changeCampaign;
      const data = {
        ad_id: this.current_ad,
        campaign: this.value._id,
      };

      api.data = data;

      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Ad is changed successfully", {
            title: "Change Ad",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("change-ad-modal");
          this.$emit("reload");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Ad is not changed, ${err.response.data.error}!`,
            {
              title: "Change Ad",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.assigning = false;
        });
    },

    nameWithLang({
      company,
      start_within,
      no_of_drivers_required,
      no_of_drivers_assigned,
      campaign_name
    }) {
      return `${campaign_name||'Not Provided'} - ${company.name} (start within:${this.moment(
        start_within
      ).format(
        "LL"
      )}) - ${no_of_drivers_assigned} out of ${no_of_drivers_required}`;
    },
  },
};
</script>
