<template>
  <div id="app">
    <GmapMap
      :center="center"
      :zoom="10"
      id="google-map-div"
      map-style-id="roadmap"
      :options="mapOptions"
      style="width: 100%; height: 100%"
      ref="mapRef"
      @click="handleMapClick(e)"
    >
      <GmapMarker
        v-if="live"
        :position="{ lat: live.lat, lng: live.lng }"
        @click="toggleInfoWindow(loc)"
        icon="https://b-seen-storage.s3.amazonaws.com/p_7GiBBhSppxKBymvPtYfg.png"
      />
      <GmapCluster v-if="showMarkerPoints">
        <GmapMarker
          v-for="(loc, index) in markerLocations"
          :key="index"
          :position="{ lat: loc.lat, lng: loc.lng }"
          @click="confirmwindow(loc)"
        />
      </GmapCluster>
      <div v-if="showPolyLine">
        <GmapPolyline
          v-for="key in Object.keys(polylinePaths)"
          :key="key"
          :path="polylinePaths[key]"
          :options="generateRandomColor"
        />
      </div>
    </GmapMap>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  props: {
    marker: {
      type: Object,
      default() {
        return { position: { lat: 24.7136, lng: 46.6753 } };
      },
    },
    showPolyLine: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showMarkerPoints: {
      type: Boolean,
      default() {
        return true;
      },
    },
    live: {
      type: Object,
    },
    center: {
      type: Object,
      default() {
        return { lat: 24.7136, lng: 46.6753 };
      },
    },
    ["markerLocations"]: {
      type: Array,
    },
    ["customHeight"]: {
      type: Number,
    },
  },

  data() {
    return {
      polylinePaths: {},
      polylinePathsColor: {},

      mapOptions: {
        disableDefaultUI: true,
      },
    };
  },
  created() {
    for (let index = 0; index < this.markerLocations.length; index++) {
      const element = this.markerLocations[index];
      let createdAt = moment(element.element.createdAt).format("YYYY-MM-DD");
      let uniqValue = createdAt + element.element.user;
      if (!this.polylinePaths[uniqValue]) {
        this.polylinePaths[uniqValue] = [];
      }
      this.polylinePaths[uniqValue].push(element);
    }
    let tempList = [];
    for (let temp in this.polylinePaths) {
      if (this.polylinePaths[temp].length > 1) {
        tempList[temp] = this.polylinePaths[temp];
      }
    }
    this.polylinePaths = tempList;
    console.log(this.polylinePaths);
  },
  mounted() {
    if (this.customHeight != null) {
      document.getElementById("google-map-div").style.height =
        this.customHeight + "px";
    }
  },
  methods: {
    confirmwindow(data) {
      console.log(data);
      Swal.fire({
        title: "Do you want to delete the selected location?",
        text: `Location information: ${data.lat},${data.lng}`,
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.toggleInfoWindow(data);
        } else {
          return;
        }
      });
    },

    toggleInfoWindow(marker) {
      if (marker.user) {
        this.$router.push({
          name: "DriverDetails",
          params: { id: marker.user },
        });
        console.log("marker", marker);
      } else {
        console.log(marker.element, "-= id");

        let id = marker.element._id;
        let api = {
          url: `user/removeDriverByID/${id}`,
          method: "POST",
        };
        console.log("api", api);
        this.generateAPI(api)
          .then((res) => {
            console.log("response", res);
            Swal.fire({
              icon: "success",
              text: res.data.message,
            });
            this.$emit("reload");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: err.data.message,
            });
            console.log(err);
          });
      }
    },
  },

  //detects location from browser

  //sets the position of marker when dragged
  handleMarkerDrag(e) {
    this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
  },

  //Moves the map view port to marker
  panToMarker() {
    this.$refs.mapRef.panTo(this.marker.position);
    this.$refs.mapRef.setZoom(18);
    this.positionChanged();
  },

  //Moves the marker to click position on the map
  handleMapClick(e) {
    this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    this.positionChanged();
    this.$emit("onMapClick", e);
  },
  positionChanged() {
    this.$emit("locationChange", this.marker.position);
  },

  computed: {
    generateRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return { strokeColor: color };
    },
  },
};
</script>

<style></style>
